<!-- 简历详情页面 -->
<template>
  <div class="box">
    <div id="codeList" class="wrap" v-loading="loading">
      <div class="get-btn-wrap" id="no-print">
        <div class="get-btn">
          <el-dropdown
            v-if="typeof data.status === 'number'"
            split-button
            @command="commandHandle"
            type="text"
            :style="{ backgroundColor: colorStatus[data.status].color }"
          >
            {{ colorStatus[data.status].type }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="index"
                v-for="(item, index) in colorStatus.slice(1)"
                :key="index"
                >{{ item.type }}</el-dropdown-item
              >
              <!-- <el-dropdown-item command="复试">复试</el-dropdown-item>
              <el-dropdown-item command="发offcr">发offcr</el-dropdown-item>
              <el-dropdown-item command="不合格">不合格</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="comment" @click="centerDialogVisible = true">沟通备注</div>
        <div class="bounce">
          <el-dropdown split-button type="text" @command="bounceHandle">
            {{ source_place[data.source_net] }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="index"
                v-for="(item, index) in source_place.slice(1)"
                :key="index"
                >{{ item }}</el-dropdown-item
              >
              <!-- <el-dropdown-item command="复试">复试</el-dropdown-item>
              <el-dropdown-item command="发offcr">发offcr</el-dropdown-item>
              <el-dropdown-item command="不合格">不合格</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="comment" @click="datetimeDialogVisible = true">
          面试时间
        </div>

        <div class="comment print" @click="goPrint">打印</div>
      </div>

      <!-- 基本信息 -->
      <div style="overflow: hidden">
        <div class="img-wrap">
          <img
            src="./../../assets/images/test/boy.png"
            alt
            v-if="data.basic_gender === '男'"
          />
          <img src="./../../assets/images/test/girl.png" alt v-else />
          <!-- <div @click="source=!source" class="jd-source"> -->
          <!-- <span>简历来源</span> -->
          <!-- <img src="./../../assets/images/test/6341640e6ee1afb369123bcfb960a40f_222_222.jpg" alt /> -->
          <!-- <div v-if="data.source_net> 0">{{source_place[data.source_net]}}</div> -->
          <!-- <transition name="bounce">
              <ul v-show="source">
                <li
                  v-for="(item,index) in source_place.slice(1)"
                  :key="index"
                  
                >{{item}}</li>
              </ul>
          </transition>-->
          <!-- </div> -->
        </div>

        <div class="all-base">
          <div class="name">{{ data.basic_name }}</div>
          <div class="base">
            <span>{{ data.basic_gender }}</span>
            <i></i>
            <span>{{ data.basic_age }}岁</span>
            <i></i>
            <span
              v-if="
                data.resume_education_experience_list &&
                data.resume_education_experience_list.length > 0
              "
              >{{
                data.resume_education_experience_list[0].education_background
              }}</span
            >
            <!-- <i></i> -->
            <!-- <span>3-5年工作经验</span> -->
          </div>
          <!-- <div class="tag">
            <span>沟通力强</span>
            <span>执行力强</span>

            <span>学习力强</span>

            <span>诚信正直</span>
          </div>-->
          <div class="expect" style="width: 300px">
            <!-- <p>
              求职状态：
              <span>求职中</span>
            </p>-->
            <p>
              期望职位：
              <span style="margin-right: 7px">{{
                data.expect_position_name_list
                  ? data.expect_position_name_list.join(" , ")
                  : ""
              }}</span>
              <!-- 
              <span
                v-for="(item, index) in data.expect_position_name_list"
                :key="index"
                style="margin-right:7px"
                >{{ item }}</span>-->
            </p>
            <p>
              期望地点：
              <span
                v-for="(item, index) in data.expect_position_place_list"
                :key="index"
                style="margin-right: 7px"
                >{{ item }}</span
              >
            </p>
            <p>
              期望薪资：
              <span
                v-if="
                  data.resume_expect_position_list &&
                  data.resume_expect_position_list.length > 0
                "
                >{{ data.resume_expect_position_list[0].salary_min }}-{{
                  data.resume_expect_position_list[0].salary_max
                }}元</span
              >
            </p>

            <p v-if="data.basic_phone != ''">
              联系方式：
              <span>{{ data.basic_phone }}</span>
            </p>

            <p style="color: #ff001b" v-if="data.state_index > 0">
              求职状态：{{ data.state_str }}
            </p>

            <p style="color: #ff001b" v-if="data.agent">
              推荐人：{{ data.agent }}
            </p>
          </div>
        </div>
      </div>
      <!-- 工作经历 -->
      <div
        class="work"
        v-if="
          data.resume_work_experience_list &&
          data.resume_work_experience_list.length > 0
        "
      >
        <div class="title">工作经历</div>
        <div
          v-for="(item, index) in data.resume_work_experience_list"
          :key="index"
        >
          <p class="company-name" v-if="item.company_name != ''">
            {{ item.company_name }}
          </p>
          <p v-if="item.end_month != 0">
            工作时间：
            <span
              >{{ item.start_year }}年{{ item.start_month }}月-{{
                item.end_year
              }}年{{ item.end_month }}月</span
            >
          </p>
          <p v-if="item.upper != 0">
            薪资水平：
            <span>{{ item.floor }}-{{ item.upper }}元</span>
          </p>

          <p>
            在职职位：
            <span>{{ item.position_name }}</span>
          </p>
        </div>
      </div>
      <!-- 教育经历 -->
      <div
        class="edu"
        v-if="
          data.resume_education_experience_list &&
          data.resume_education_experience_list.length > 0 &&
          data.resume_education_experience_list[0].school != '' &&
          data.resume_education_experience_list[0].end_month != '' &&
          data.resume_education_experience_list[0].major != ''
        "
      >
        <div class="title">教育经历</div>
        <p
          v-for="(item, index) in data.resume_education_experience_list"
          :key="index"
        >
          <span v-if="item.school != ''">{{ item.school }}</span>

          <span v-if="item.end_month != 0">
            <i></i>
            {{ item.start_year }}年{{ item.start_month }}月-{{
              item.end_year
            }}年{{ item.end_month }}月
          </span>

          <span v-if="item.major != ''">
            <i></i>
            {{ item.major }}
          </span>
        </p>
      </div>
      <!-- 自我评价  basic_self_evaluation-->
      <div class="self" v-if="data.basic_self_evaluation != ''">
        <div class="title">自我评价</div>
        <div class="self-word">{{ data.basic_self_evaluation }}</div>
      </div>
      <!-- 面试备注 -->
      <div class="self" v-if="data.remarks">
        <div class="title">沟通备注</div>
        <div class="self-word">{{ data.remarks }}</div>
      </div>
    </div>

    <el-dialog
      title="沟通备注"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <textarea class="area" v-model="remarks"></textarea>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ResumeRemark">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="面试时间"
      :visible.sync="datetimeDialogVisible"
      width="500px"
      center
    >
      <el-date-picker
        v-model="cdatetime"
        type="datetime"
        placeholder="选择时间"
        value-format="yyyy-MM-dd HH:mm"
        format="yyyy-MM-dd HH:mm"
      ></el-date-picker>

      <span slot="footer" class="dialog-footer">
        <el-button @click="datetimeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="c_datetime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getResumeDetails,
  getResumeRemark,
  getResumeSource,
  getResumeStatus,
  set_cdatetime,
} from "@/api/tool";
import print from "print-js";

export default {
  //import引入的组件需要注入到对象中才能使用

  components: {},
  data() {
    //这里存放数据
    return {
      id: "",
      name: "",
      loading: true,
      data: {},
      expectData: { position_name: "", city_name: "" },
      //获取简历
      resumeGet: false,
      //标记
      signShow: false,

      //下拉菜单背景色
      // dropdownBgc: ['primary', 'success', 'warning', 'danger', 'info'],
      //下拉菜单背景色下标
      dropdownBgcIndex: 0,

      centerDialogVisible: false,
      //不合格，发offer,未入职，可复试
      colorStatus: [
        { color: "#ccc", type: "未标记" },
        { color: "#FF435A", type: "不合格" },
        { color: "#ff9900", type: "可复试" },
        { color: "#3EFF59", type: "发offer" },
        { color: "#1D7BD8", type: "未入职" },
        { color: "#149c18", type: "已入职" },
      ],
      //简历来源
      source: false,
      source_place: [
        "简历来源",
        "58同城",
        "智联招聘",
        "BOSS直聘",
        "前程无忧",
        "其他",
      ],
      source_place_name: "",
      //建立备注
      remarks: "",

      datetimeDialogVisible: false,
      cdatetime: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //打印
    goPrint() {
      printJS({
        printable: "codeList",
        type: "html",
        targetStyles: ["*"],
        scanStyles: true,
        ignoreElements: ["no-print"],
      });
    },

    //下拉菜单
    commandHandle(command) {
      console.log(command);

      let data = {
        resume_id: this.id,
        status: command + 1,
      };
      getResumeStatus(data).then((res) => {
        if (res.code === 0) {
          this.getData();
        }
      });
    },
    bounceHandle(command) {
      console.log(command);
      let data = { resume_id: this.id, source_net: command + 1 };
      getResumeSource(data).then((res) => {
        if (res.code === 0) {
          this.getData();
        }
      });
    },
    //面试备注
    ResumeRemark() {
      this.centerDialogVisible = false;
      let data = {
        resume_id: this.id,
        remarks: this.remarks,
      };

      getResumeRemark(data).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.getData();

          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },

    //面试时间
    c_datetime() {
      this.datetimeDialogVisible = false;
      let data = {
        resume_id: this.id,
        name: this.name,
        cdatetime: this.cdatetime,
      };

      if (this.cdatetime == "") {
        this.$message({
          message: "请选择面试时间！",
          type: "error",
        });
        return;
      }

      console.log("选择的时间：" + this.cdatetime);

      set_cdatetime(data).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.getData();

          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },

    getData() {
      this.loading = true;
      getResumeDetails(this.id).then((res) => {
        if (res.code === 0) {
          this.expectData.position_name = "";
          this.expectData.city_name = "";
          this.data = res.data;
          this.remarks = res.data.remarks;
          this.name = res.data.basic_name;
          console.log(res.data);
          console.log(this.data.resume_expect_position_list);
          // let arr = this.data.resume_expect_position_list
          // // let obj = { position_name: '', city_name: '' }
          // arr.filter(item => {
          //   this.expectData.position_name += item.position_name + ','
          //   this.expectData.city_name +=
          //     item.city_name + item.district_name + ','
          // })
          // console.log(arr)
          this.loading = false;
        }

        // console.log(res)
      });
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.typeShow(this.$route.query.from)
    // console.log(this.$route.query.id)
    this.id = this.$route.query.id;
    this.getData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */

.box {
  @include padding;
  min-width: 1100px;
  padding-bottom: 20px;
  min-height: 70vh;
}
.wrap {
  padding: 20px 50px;
  min-height: 80vh;

  // overflow: auto;
  // height: calc(90vh - 100px);
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: $box-shadow;
  // position: relative;
}
.get-btn-wrap {
  width: 100%;
  height: 50px;
}
.get-btn {
  float: right;
}

.get-btn::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

/* 头像 */
.img-wrap {
  width: 110px;
  height: 110px;
  float: left;
  text-align: center;
}
.img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.all-base {
  float: left;
  margin-left: 120px;
}
.name {
  font-size: 28px;
  color: $jd_fontColor;
  margin-bottom: 10px;
}
.base {
  font-size: $jd_fontSize;
  color: $selfColor;
}
/* 基本信息间隔 */
i {
  margin: 0 15px;
  border-right: 1px solid #ccc;
}
// 标签

.tag span {
  width: 80px;
  height: 30px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 10px;
  text-align: center;
  line-height: 30px;
  background-color: $main_bc;
  font-size: $jd_fontSize;
  color: $main_color;
}
// 期望
.expect p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
}
.expect p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
}
.work,
.edu,
.self {
  margin-top: 39px;
}
.work {
  clear: both;
}
.company-name {
  font-size: 18px;
  color: $jd_fontColor;
  padding-left: 10px !important;
}
.work p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
  padding: 0 30px;
  margin: 10px 0 0 0;
}
.work span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.edu p {
  padding: 0 30px;
}
.edu p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.self-word {
  font-size: $jd_fontSize;
  color: $selfColor;
  // background: red;
  padding: 0px 30px;
}
.area {
  border: 1px solid #ff9900;
  width: 450px;
  height: 100px;
  min-width: 450px;
  max-width: 450px;
  font-size: 16px;
  color: $selfColor;
  // text-align: center;
  // margin: 0 auto;
  display: inline-block;
  font-family: auto;
}
.area:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: 0 0 10px #719ece;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-exit 0.5s;
}
@keyframes bounce-in {
  0% {
    transform: rotateZ(-180deg);
    transform-origin: 0 50%;
  }
  // 50% {
  //   transform: rotateZ(180deg);
  //   transform-origin: 0 50%;
  // }
  100% {
    transform: rotateZ(0deg);
    transform-origin: 0 50%;
  }
}
@keyframes bounce-exit {
  0% {
    transform: rotateZ(0deg);
    transform-origin: 0 50%;
  }
  // 50% {
  //   transform: rotateZ(180deg);
  //   transform-origin: 0 50%;
  // }
  100% {
    transform: rotateZ(-180deg);
    transform-origin: 0 50%;
  }
}

.print {
  float: right;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #6d9e1e;
  cursor: pointer;
  padding: 0 10px;
  margin-right: 10px;
}

/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 3mm; /* this affects the margin in the printer settings */
}

html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}

body {
  border: solid 1px blue;
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
}
/*去除页眉页脚*/
</style>
